html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: black;
    color: white;
    margin-top:-1rem;
}
#videoLoop {
  video::-webkit-media-controls{
    display: none;
    -webkit-appearance: none;
  }
}

video::-webkit-media-controls {
  display: none;
}
:root {
  --primary-color: #03032D;
  --secondary-color: #B01AAC;
}

@font-face {
  font-family: 'Subway Ticker';
  src: local('Subway Ticker'), url(./fonts/SubwayTickerGrid.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url(./fonts/Inter/static/Inter-Light.ttf) format('truetype');
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


#rssBlock{
  left:0px;
  height: 80px;
  background: black;
  z-index:99;
  position: absolute;
  width:100%;
  bottom: 80px;
  overflow: hidden;
  overflow-x: hidden;
}

/*remove p*/
.cnnContents {
  width:100%;
  padding-top: 20px;
  margin:0 auto;
  font-size: 30px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: 'Museo';
  font-weight: 300;
}

.marqueeStyle {
  font-family: Subway Ticker;
  font-size: 18px;
  color:white;
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 40s linear infinite;
  animation: scrolling-left1 40s linear infinite;
}

.marqueeStyle2 {
  font-family: Subway Ticker;
  font-size: 18px;
  color:white;
  /* color:blue; */
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 40s linear infinite;
  animation: scrolling-left2 40s linear infinite;
  /* animation-delay: 20s; */
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
    0% {transform: translateX(0%);
        -webkit-transform: translateX(0%);}
      100% {transform: translateX(-200%);
          -webkit-transform: translateX(-200%);}
}
@keyframes scrolling-left2 {
    0% {transform: translateX(0%);
        -webkit-transform: translateX(0%);}
      100% {transform: translateX(-200%);
          -webkit-transform: translateX(-200%);}
}

/* @-webkit-keyframes scrolling-left1 {
    0% {-webkit-transform: translateX(100%);}
      100% {-webkit-transform: translateX(-100%);}
}
@-webkit-keyframes scrolling-left2 {
    0% {-webkit-transform: translateX(0%);}
      100% {-webkit-transform: translateX(-200%);}
} */


@media (max-width: 600px) {
  #rssBlock{
    top:70%;
  }
}